import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled/macro';
import { useEffect, useMemo, useState, } from 'react';
import { CircularProgress, useTheme } from '@mui/material';
import { ReactComponent as PlusIcon } from '@icons/wolfkit-light/plus-light.svg';
import { ReactComponent as AlertIcon } from '@icons/wolfkit-solid/alert-circle-solid.svg';
import { useAppDispatch, useAppSelector } from '@app/store/Hooks';
import { submitStep, selectExchange, cancelEditStep, } from '@entities/portfolio';
import Button from '@shared/ui/buttons/Button';
import { ContainerColumn, ContainerRow } from '@components/styled';
import ExchangeIcon from '@shared/ui/icons/ExchangeIcon';
import { ActiveText, BodyMediumSemiBold, Subtitle } from '@components/styled/Typography';
import Icon from '@shared/ui/icons/Icon';
import { useGetExchangeAccountsQuery } from '@shared/api/exchange';
import { IsDefined } from '@utils/js-ts';
import BidToQueueStep from './BidToQueueStep';
const ItemContainer = styled(ContainerRow, { shouldForwardProp: propName => propName !== 'selected' && propName !== 'disabled' })((props) => (Object.assign({ position: 'relative', width: 'auto', height: 'auto', cursor: (props.selected || props.disabled) ? 'default' : 'pointer', alignItems: 'center', justifyContent: 'space-between', padding: props.theme.spacing_sizes.m, userSelect: 'none', backgroundColor: props.theme.customColors.menu.item.select, '&:before': {
        position: 'absolute',
        content: '""',
        left: 0,
        borderLeft: '3px solid transparent',
        height: '100%',
    } }, props.selected && ({
    '&:before': {
        borderLeftColor: props.theme.palette.primary.main,
    },
    backgroundColor: props.theme.customColors.surface.surface,
    borderColor: props.theme.palette.primary.main,
}))));
const Container = styled(ContainerColumn)((props) => ({
    height: 'auto',
    [`${ItemContainer}`]: {
        border: `1px solid ${props.theme.customColors.button.pill.border}`,
        borderTop: 'none',
    },
    [`${ItemContainer}:first-of-type`]: {
        borderTop: `1px solid ${props.theme.customColors.button.pill.border}`,
        borderTopLeftRadius: props.theme.shape.borderRadius,
        borderTopRightRadius: props.theme.shape.borderRadius,
        '&:before': {
            borderTopLeftRadius: props.theme.shape.borderRadius,
        },
    },
    [`${ItemContainer}:last-child`]: {
        borderBottomLeftRadius: props.theme.shape.borderRadius,
        borderBottomRightRadius: props.theme.shape.borderRadius,
        '&:before': {
            borderBottomLeftRadius: props.theme.shape.borderRadius,
        },
    },
}));
const LoaderContainer = styled(ContainerColumn)(() => ({
    alignItems: 'center',
    justifyContent: 'center',
    height: 'auto',
    minHeight: '100px',
}));
const ItemPartContainer = styled(ContainerRow)((props) => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.s,
}));
const ExchangeName = styled(Subtitle)(() => ({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    maxWidth: '145px',
}));
const AddNewExchangeTextContaner = styled(ContainerRow)((props) => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.xs,
}));
const CollapsedContainer = styled(ContainerRow)(() => ({
    height: 'auto',
    alignItems: 'center',
    justifyContent: 'space-between',
}));
const ExchangeNameCollapsed = styled(ContainerRow)((props) => ({
    height: 'auto',
    width: 'auto',
    alignItems: 'center',
    gap: props.theme.spacing_sizes.xs,
}));
const ItemBalance = styled(ContainerRow)(() => ({
    width: 'auto',
    height: 'auto',
    alignItems: 'center',
    gap: '2px',
}));
const SelectExchangeItem = ({ className = undefined, selected = false, disabled = false, onSelect, item, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const onClick = () => {
        if (!disabled) {
            onSelect(item.id);
        }
    };
    return (_jsxs(ItemContainer, { className: className, onClick: onClick, selected: selected, disabled: disabled, children: [_jsxs(ItemPartContainer, { children: [_jsx(ExchangeIcon, { type: item.type }), _jsx(ExchangeName, { children: item.properties.connectionName })] }), _jsxs(ItemPartContainer, { children: [_jsxs(ItemBalance, { children: [_jsx(Subtitle, { color: disabled ? theme.palette.error.main : theme.palette.text.primary, children: `$ PLACEHOLDER: ${item.id}` }), disabled && (_jsx(Icon, { IconComponent: AlertIcon, size: 16, color: theme.palette.error.main }))] }), _jsx(Button, { variant: 'filled', color: 'primary', size: 'small', children: _jsx(ActiveText, { children: t('portfolio.subscription.deposit') }) })] })] }));
};
const AddNewExchangeItem = ({ className = undefined, onClick, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    return (_jsx(ItemContainer, { className: className, onClick: onClick, selected: false, disabled: false, children: _jsxs(AddNewExchangeTextContaner, { children: [_jsx(Icon, { size: 16, color: theme.palette.primary.main, IconComponent: PlusIcon }), _jsx(Subtitle, { color: theme.palette.primary.main, children: t('portfolio.subscription.add_new_exchange') })] }) }));
};
const SelectExchange = ({ exchanges, selectedExchangeId = undefined, onSelect = () => { }, }) => {
    const { portfolio } = useAppSelector(state => state.portfolioSubscription);
    return (_jsxs(Container, { children: [IsDefined(portfolio) &&
                exchanges.map(exchange => (_jsx(SelectExchangeItem, { item: exchange, selected: selectedExchangeId === exchange.id, 
                    // TODO-FIX-MOCKS: either backend should provide totalBalance,
                    // or we should calculate it based on ExchangeWallets
                    // disabled={exchange.totalBalance < portfolio.min_balance}
                    onSelect: onSelect }, exchange.id))), _jsx(AddNewExchangeItem, { onClick: () => { } })] }));
};
const StepType = 'select_exchange';
const SelectExchangeCollapsed = ({ exchange = undefined, }) => {
    if (!IsDefined(exchange)) {
        return null;
    }
    return (_jsxs(CollapsedContainer, { children: [_jsxs(ExchangeNameCollapsed, { children: [_jsx(ExchangeIcon, { type: exchange.type, size: 24 }), _jsx(ExchangeName, { children: exchange.properties.connectionName })] }), _jsx(BodyMediumSemiBold, { children: `$ PLACEHOLDER: ${exchange.id}` })] }));
};
const BidToQueueSelectExchangeStep = ({ stepNumber, }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { auth } = useAppSelector((state) => state.auth);
    const { user } = useAppSelector(state => state.user);
    const { subscriptionData } = useAppSelector(state => state.portfolioSubscription);
    const [selectedExchangeId, setSelectedExchangeId] = useState(undefined);
    useEffect(() => {
        if (IsDefined(subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.exchangeId)) {
            setSelectedExchangeId(subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.exchangeId);
        }
    }, [subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.exchangeId]);
    const { data, isFetching, isError } = useGetExchangeAccountsQuery();
    const selectedExchange = useMemo(() => data === null || data === void 0 ? void 0 : data.find(e => e.id === (subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.exchangeId)), [data, subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.exchangeId]);
    const onSelectExchange = (exchangeId) => {
        setSelectedExchangeId(exchangeId);
    };
    const onAction = (actionType) => {
        if (actionType === 'continue' && IsDefined(selectedExchangeId)) {
            dispatch(selectExchange(selectedExchangeId));
            dispatch(submitStep(StepType));
        }
        else if (actionType === 'update' && IsDefined(selectedExchangeId)) {
            dispatch(selectExchange(selectedExchangeId));
            dispatch(cancelEditStep(StepType));
        }
        else if (actionType === 'cancel') {
            setSelectedExchangeId(subscriptionData === null || subscriptionData === void 0 ? void 0 : subscriptionData.exchangeId);
        }
    };
    const errorMessage = useMemo(() => t('portfolio.subscription.exchange_balance_error'), [t]);
    return (_jsxs(_Fragment, { children: [isFetching && (_jsx(LoaderContainer, { children: _jsx(CircularProgress, {}) })), !isFetching &&
                !isError &&
                data && (_jsx(BidToQueueStep, { stepNumber: stepNumber, type: StepType, title: t('portfolio.subscription.select_exchange_label'), disabled: !IsDefined(selectedExchangeId), onAction: onAction, 
                // errorMessage={errorMessage}
                collapsedContent: _jsx(SelectExchangeCollapsed, { exchange: selectedExchange }), children: _jsx(SelectExchange, { exchanges: data, selectedExchangeId: selectedExchangeId, onSelect: onSelectExchange }) }))] }));
};
export default BidToQueueSelectExchangeStep;
